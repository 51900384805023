<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>Invitados</v-card-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-card-text>
          <v-row>
            <v-col cols="5">
              <v-text-field
                v-model="invitado.nombre"
                label="Nombre"
                class="uppercase"
              />
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="invitado.apellidos"
                label="Apellidos"
                class="uppercase"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="invitado.n_invitados"
                label="Número de invitados"
                type="number"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-form>
                <v-btn class="mb-2" color="success" dark @click="saveInvitados">
                  Guardar
                  <v-icon>mdi-content-save-outline</v-icon>
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" offset="6">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Búsqueda"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-data-table
            locale="es"
            :search="search"
            :headers="headers"
            :items="lista_invitados"
            item-key="idinvitados"
            class="elevation-1 table-one"
            multi-sort
          >
            <template v-slot:item.action="{ item }">
              <div class="d-flex">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="imprimirQR(item)"
                    >
                      <v-icon>mdi-qrcode-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>Imprimir Código QR</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="editarInvitado(item.idinvitados)"
                      color="success"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon>mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="danger"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="eliminarInvitado(item.idinvitados)"
                    >
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
import { url } from "../../main.js";
export default {
  data() {
    return {
      invitado: {
        nombre: "",
        apellidos: "",
        n_invitados: 0,
        estado: ""
      },
      lista_invitados: [],
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombre"
        },

        { text: "Apellidos", value: "apellidos" },
        { text: "No. Invitados", value: "n_invitados" },
       { text: "Estado", value: "descripcion" },

        { text: "Acción", value: "action" }
      ],
      search: ""
    };
  },
  mounted() {
    this.verInvitados();
  },
  methods: {
    saveInvitados() {
      if (this.validar()) {
        this.invitado.nombre = this.invitado.nombre.toUpperCase();
        this.invitado.apellidos = this.invitado.apellidos.toUpperCase();

        // if (this.validarcampos()) {
        //    if (this.cliente != null) {
        this.axios
          .post(url + "apiguests.php/guest", this.invitado)
          .then(respuesta => {
            console.log(respuesta);
            if (respuesta.data.status == 200) {
              this.$swal("", respuesta.data.msg, "success");
              this.limpiarcampos();

              this.verInvitados();
            }
          })
          .catch(function() {
            console.log("FAILURE ver!!");
          });
      } else {
        this.$swal("Debe ingresar los datos del invitado");
      }
    },
    validar() {
      if (
        this.invitado.nombre != "" &&
        this.invitado.apellidos != "" &&
        this.invitado.n_invitados != ""
      ) {
        return true;
      }
      return false;
    },
    limpiarcampos() {
      this.invitado = {};
    },
    verInvitados() {
      this.axios
        .get(url + "apiguests.php/guests")
        .then(respuesta => {
          console.log(respuesta);
          this.lista_invitados = respuesta.data;

          console.log(this.lista_invitados);
        })
        .catch(function() {
          console.log("FAILURE ver!!");
        });
    },
    
    imprimirQR(item) {
		window.open(url+"qrpdf.php?id="+item.idinvitados, "_blank");		


    },
    editarInvitado(idinvitados) {
       this.axios
        .get(url + "apiguests.php/guest/"+idinvitados)
        .then(respuesta => {
          console.log(respuesta);
          this.invitado = respuesta.data;

          console.log(this.invitado);
        })
        .catch(function() {
          console.log("FAILURE ver!!");
        });
    },
    eliminarInvitado(idinvitados) {
     
      this.$swal({
        title: "Confirmación",
        text: "¿Desea eliminar al invitado?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sí, eliminar",
      }).then((result) => {
        if (result.value) {
          this.axios
            .delete(url + "apiguests.php/guest/"+idinvitados)
            .then((respuesta) => {
              console.log(respuesta);

              if (respuesta.data.status === 200) {
                this.$swal("",respuesta.data.msg,"success");
                this.verInvitados();
              }
            });
        }
      });
    }
  }
};
</script>
