var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-title',[_vm._v("Invitados")]),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{staticClass:"uppercase",attrs:{"label":"Nombre"},model:{value:(_vm.invitado.nombre),callback:function ($$v) {_vm.$set(_vm.invitado, "nombre", $$v)},expression:"invitado.nombre"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{staticClass:"uppercase",attrs:{"label":"Apellidos"},model:{value:(_vm.invitado.apellidos),callback:function ($$v) {_vm.$set(_vm.invitado, "apellidos", $$v)},expression:"invitado.apellidos"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"label":"Número de invitados","type":"number"},model:{value:(_vm.invitado.n_invitados),callback:function ($$v) {_vm.$set(_vm.invitado, "n_invitados", $$v)},expression:"invitado.n_invitados"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-form',[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"success","dark":""},on:{"click":_vm.saveInvitados}},[_vm._v(" Guardar "),_c('v-icon',[_vm._v("mdi-content-save-outline")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","offset":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"locale":"es","search":_vm.search,"headers":_vm.headers,"items":_vm.lista_invitados,"item-key":"idinvitados","multi-sort":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""},on:{"click":function($event){return _vm.imprimirQR(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-qrcode-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Imprimir Código QR")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.editarInvitado(item.idinvitados)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"danger","dark":"","icon":""},on:{"click":function($event){return _vm.eliminarInvitado(item.idinvitados)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }